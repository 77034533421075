import React, { useEffect, useState } from 'react';
import anime from 'animejs';
import './index.scss';

const Drawing = () => {
  const [key, setKey] = useState(0);
  const [viewBox, setViewBox] = useState("0 0 800 800"); // Initial viewBox value


  const animateSVG = () => {
    anime({
      targets: '.st0, .st1',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutQuad',
      duration: 6000,
      delay: function(el, i) {
        return i * 250;
      },
    });
  };

  useEffect(() => {
    animateSVG();
  }, [key]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setViewBox("120 0 1000 800"); // Adjust viewBox for mobile screen
      } else {
        setViewBox("0 0 800 800"); // Default viewBox for desktop screen
      }
    };

    handleResize(); // Call handleResize initially
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Run this effect only once when the component mounts

  return (
    <div>
      <svg viewBox={viewBox} className="logo-svg" style={{ fill: 'none', stroke: '#1eb8ff' }}>
        <g id="bg"></g>
        <g id="graphic">
          <g>
          <path className="st0" d=" 
            M521.004700,561.000000 
              C521.004700,572.666199 521.046265,583.832520 520.993896,594.998474 
              C520.932739,608.047974 525.192017,619.574036 533.340515,629.639587 
              C535.167480,631.896362 537.779114,633.506226 539.957153,635.493164 
              C543.160583,638.415527 543.086792,641.429565 540.033508,644.442627 
              C514.452332,669.686218 488.912598,694.972229 463.185211,720.065857 
              C461.757690,721.458252 458.190033,722.346008 456.674805,721.523315 
              C454.856171,720.535828 453.161163,717.466553 453.147034,715.287476 
              C452.929840,681.790344 452.995300,648.291321 452.995270,614.792786 
              C452.995209,562.795044 453.035980,510.797180 452.867249,458.799988 
              C452.859375,456.364563 451.608978,453.296173 449.879028,451.605255 
              C437.324341,439.334106 424.537048,427.299347 411.743042,415.275208 
              C403.196564,407.243042 394.529663,399.338745 385.896179,391.399475 
              C369.489014,376.311646 353.070465,361.236145 336.637604,346.176270 
              C336.056366,345.643585 335.201843,345.415649 334.595306,344.902985 
              C330.648254,341.566925 327.433655,338.293884 327.816315,332.141083 
              C328.466766,321.681976 327.795441,311.146210 328.158264,300.659210 
              C328.234283,298.461304 329.923462,295.226074 331.705933,294.487183 
              C333.495789,293.745300 336.940613,294.831482 338.612244,296.305206 
              C354.633636,310.429932 370.432892,324.806610 386.532410,339.324371 
              C401.934875,324.811249 416.926605,310.576141 432.043427,296.475159 
              C444.482239,284.872253 457.080872,273.440857 469.587738,261.910736 
              C482.704895,249.817993 495.802399,237.703934 508.898773,225.588669 
              C522.485962,213.019348 536.061523,200.437576 549.641846,187.860855 
              C563.099609,175.397644 576.521790,162.895706 590.030884,150.488419 
              C595.848022,145.145721 601.968689,140.129776 607.708435,134.708084 
              C610.621643,131.956238 613.781982,132.323837 616.213135,134.184387 
              C633.574768,147.471115 650.861755,160.863190 667.892395,174.569824 
              C673.208008,178.847931 672.422974,183.553452 666.113281,186.310684 
              C651.118469,192.863144 642.775146,204.418137 639.471985,220.048172 
              C639.100769,221.804596 639.023376,223.649887 639.022949,225.454010 
              C639.002014,310.950287 638.856140,396.447113 639.150574,481.942383 
              C639.199768,496.230164 644.313782,508.894104 656.859924,517.458862 
              C661.082520,520.341492 661.380554,525.181030 657.710083,528.797607 
              C637.569092,548.642151 617.417725,568.476379 597.212524,588.255432 
              C592.369080,592.996704 587.261780,597.467529 582.365051,602.155945 
              C579.929626,604.487671 577.376221,605.812256 573.964294,604.505798 
              C570.365906,603.127808 569.975159,600.205322 569.976807,596.898376 
              C570.005310,539.567505 569.870178,482.236328 570.038086,424.905975 
              C570.176514,377.650787 570.705933,330.396912 570.978149,283.141937 
              C571.094482,262.956879 570.997620,242.770584 570.997620,222.584839 
              C570.439636,222.506882 569.881714,222.428909 569.323730,222.350952 
              C562.616333,228.334457 555.787048,234.187866 549.227173,240.328949 
              C536.044373,252.669998 523.088135,265.253296 509.887848,277.575378 
              C498.427246,288.273468 486.716980,298.703552 475.193237,309.334656 
              C461.100677,322.335571 447.132355,335.471222 433.019745,348.450134 
              C426.844727,354.129089 420.445129,359.563934 415.114868,364.257690 
              C417.428070,367.041718 419.346985,369.918915 421.805450,372.219574 
              C440.270538,389.499695 458.829224,406.679871 477.386017,423.861755 
              C485.381500,431.264832 493.406250,438.637146 501.485046,445.949036 
              C506.971954,450.915131 512.751526,455.573181 518.023193,460.752655 
              C519.653992,462.354889 520.866821,465.213470 520.880554,467.505066 
              C521.065369,498.502533 521.004639,529.501465 521.004700,561.000000 
            M439.470093,297.021698 
              C421.804474,313.297791 404.138885,329.573853 386.398590,345.918732 
              C368.886353,330.187988 351.409851,314.489349 333.007172,297.958740 
              C333.007172,311.209320 332.906952,323.138245 333.136169,335.060852 
              C333.164276,336.523285 334.720306,338.209534 335.970856,339.350983 
              C341.093628,344.026764 346.466339,348.429230 351.576569,353.118103 
              C368.906555,369.019318 386.205627,384.954834 403.434753,400.965271 
              C415.100647,411.806000 426.624359,422.799713 438.221405,433.714630 
              C443.418701,438.606262 448.207550,444.091766 454.000122,448.135529 
              C457.960022,450.899902 458.031067,453.899689 458.029419,457.737122 
              C457.993134,542.231812 458.001709,626.726501 458.001709,711.221191 
              C458.001709,713.107788 458.001709,714.994446 458.001709,716.881042 
              C458.539062,717.152710 459.076385,717.424377 459.613739,717.696045 
              C484.960907,691.248230 510.308075,664.800354 535.655212,638.352478 
              C536.127075,638.288452 535.006409,637.287598 533.836121,636.348572 
              C521.187195,626.198486 516.086365,612.550415 516.039917,596.829468 
              C515.917175,555.332458 515.950562,513.834656 516.067200,472.337585 
              C516.079834,467.845032 515.183960,464.868591 511.388245,461.531036 
              C495.832886,447.853180 480.935089,433.427887 465.781647,419.292267 
              C461.407837,415.212219 457.010651,411.156647 452.586121,407.131683 
              C437.414673,393.330414 422.226349,379.547699 406.434753,365.204071 
              C407.711426,364.250763 408.842621,363.547028 409.809082,362.663177 
              C421.687714,351.800018 433.543091,340.911438 445.402710,330.027496 
              C457.850769,318.603607 470.341766,307.226013 482.733459,295.741333 
              C502.062103,277.827515 521.329529,259.847595 540.609619,241.881531 
              C550.813599,232.372971 560.974121,222.817566 571.204224,213.337296 
              C572.157959,212.453476 573.473267,211.959808 575.546692,210.740387 
              C575.546692,341.178345 575.546692,470.548065 575.546692,601.333801 
              C577.794434,599.387390 579.011963,598.392273 580.163269,597.325684 
              C585.015015,592.830994 589.943909,588.413818 594.669373,583.789368 
              C614.282227,564.595947 633.824158,545.329895 653.415833,526.114807 
              C655.329773,524.237671 655.584045,523.274048 652.997803,521.306458 
              C641.168335,512.306763 634.208862,500.036377 634.155212,485.302307 
              C633.837219,397.975616 633.909363,310.646942 634.111267,223.319565 
              C634.136414,212.435135 638.237366,202.374664 646.063416,194.696518 
              C651.898926,188.971298 658.891907,184.425781 665.935608,178.911667 
              C648.330383,165.377579 630.184509,151.427856 612.358582,137.724106 
              C605.387146,143.771957 599.002441,149.044479 592.915710,154.641144 
              C574.506714,171.567947 556.232910,188.641602 537.869141,205.617783 
              C520.980530,221.230331 503.996582,236.740005 487.141510,252.388580 
              C471.362640,267.038055 455.697754,281.810303 439.470093,297.021698 
            z"/>
            <path className="st0" d="
            M239.300110,627.201538 
              C246.502411,617.310120 250.165894,606.899170 250.138092,594.667297 
              C249.895294,487.848236 249.921783,381.028198 250.185089,274.209229 
              C250.201019,267.744598 247.816925,263.720917 243.413925,259.872192 
              C236.464951,253.797989 229.636185,247.583160 222.851959,241.324448 
              C215.721191,234.746048 208.702637,228.046051 201.002213,220.804001 
              C201.002213,223.315079 201.002197,225.071075 201.002197,226.827057 
              C201.002167,350.311188 201.002823,473.795288 201.000000,597.279419 
              C200.999863,602.987915 198.659103,605.657288 194.025955,604.919434 
              C192.720764,604.711670 191.353516,603.777283 190.372650,602.812378 
              C165.111588,577.962891 139.891968,553.071106 114.682312,528.169373 
              C110.680511,524.216431 110.972198,520.256348 115.481339,517.134216 
              C127.001076,509.157959 132.695038,497.349487 132.764389,484.179291 
              C133.217972,398.026093 133.232605,311.868134 132.794067,225.714767 
              C132.709259,209.053970 124.372177,195.993866 109.144073,187.979797 
              C106.435356,186.554291 103.674889,184.948715 101.513321,182.837540 
              C99.194527,180.572784 99.549614,177.490356 102.085411,175.543045 
              C119.907600,161.856949 137.872009,148.356064 155.699356,134.676620 
              C159.345932,131.878510 162.009171,132.618225 165.141312,135.548218 
              C183.119400,152.365921 201.220291,169.052399 219.283478,185.779099 
              C228.132874,193.973770 236.976852,202.174408 245.859512,210.332901 
              C247.240021,211.600876 248.814804,212.657318 250.997787,214.354187 
              C250.997787,211.451584 250.997772,209.517166 250.997772,207.582748 
              C250.997787,181.919373 251.096405,156.255371 250.928085,130.593094 
              C250.886261,124.218628 249.979553,117.702133 244.621368,113.302917 
              C242.223297,111.334045 239.105652,109.910583 236.083725,109.097382 
              C228.363647,107.019905 227.910202,105.011467 227.897766,99.177238 
              C227.866989,84.731308 227.704849,84.940773 242.024292,84.953217 
              C339.844971,85.038246 437.665741,84.997505 535.486511,84.998413 
              C542.803833,84.998482 543.999084,86.222382 544.002197,93.689209 
              C544.002258,93.855858 544.002136,94.022499 544.002197,94.189148 
              C544.004272,106.216644 543.996704,106.194305 532.651184,110.002068 
              C524.691101,112.673607 521.329712,117.327301 521.142273,128.148422 
              C520.703674,153.470901 520.829468,178.808395 521.158203,204.134201 
              C521.218689,208.794342 519.302856,211.528732 516.271973,214.232132 
              C509.755615,220.044403 503.242126,225.860474 496.779694,231.732468 
              C485.424896,242.049866 474.178406,252.488617 462.682739,262.645844 
              C461.364166,263.810944 458.338715,264.364075 456.837067,263.653351 
              C455.129028,262.845001 454.056458,260.392975 453.081604,258.466217 
              C452.611542,257.537048 452.997986,256.174103 452.997986,255.007599 
              C452.997833,213.679581 452.997803,172.351547 452.997803,131.023529 
              C452.997803,129.213287 452.997803,127.403046 452.997803,125.301743 
              C407.992371,125.301743 363.267853,125.301743 318.002197,125.301743 
              C318.002197,127.274872 318.002197,129.216431 318.002197,131.157990 
              C318.002197,324.799774 318.002197,518.441528 318.002136,712.083313 
              C318.002136,713.416504 318.416656,714.939941 317.911682,716.041931 
              C316.954590,718.130615 315.859314,720.669861 314.065918,721.678223 
              C312.896790,722.335571 309.875122,721.155945 308.571381,719.886902 
              C297.467773,709.078735 286.629395,697.999023 275.591187,687.122742 
              C261.859406,673.592407 247.990967,660.200745 234.226303,646.703613 
              C228.302460,640.894958 228.332214,638.177124 234.095718,632.411987 
              C235.745941,630.761292 237.389008,629.103333 239.300110,627.201538 
            M313.000488,129.538422 
              C313.000488,126.435760 313.000488,123.333107 313.000488,120.342819 
              C361.683258,120.342819 409.743774,120.342819 458.237976,120.342819 
              C458.237976,166.722916 458.237976,212.761978 458.237976,259.713440 
              C461.779144,256.764008 464.731567,254.512100 467.448486,252.004959 
              C477.445465,242.779816 487.341034,233.444794 497.322845,224.203049 
              C502.163849,219.721008 506.913849,215.102356 512.091187,211.037369 
              C514.841614,208.877930 516.099548,206.822723 516.079224,203.263245 
              C515.932129,177.598846 515.787537,151.930618 516.099609,126.269165 
              C516.234619,115.169930 521.282715,108.337677 531.627625,104.948685 
              C534.052063,104.154434 536.462891,103.318810 538.619507,102.590225 
              C538.619507,98.242317 538.619507,94.176796 538.619507,90.229401 
              C436.631287,90.229401 335.024078,90.229401 233.208420,90.229401 
              C233.208420,94.398842 233.208420,98.345459 233.208420,102.555885 
              C233.992142,102.798248 234.862595,103.134430 235.763184,103.335953 
              C251.540604,106.866615 255.996109,116.644127 255.998245,131.571487 
              C256.002502,161.236511 255.999588,190.901550 255.999634,220.566574 
              C255.999634,222.247818 255.999619,223.929062 255.999619,226.064713 
              C223.653290,196.208923 192.004730,166.997162 160.017990,137.473251 
              C141.606079,151.616470 123.429817,165.578674 104.933197,179.786972 
              C107.099434,180.980743 108.782021,181.937302 110.490562,182.844986 
              C128.345032,192.330307 137.951981,207.012451 137.969116,227.385834 
              C138.041031,312.881042 138.023651,398.376404 137.920670,483.871552 
              C137.916031,487.724274 137.018845,491.639130 136.092056,495.415771 
              C133.230942,507.074463 126.209503,515.987305 116.511330,523.310974 
              C142.818085,549.286072 168.809891,574.950256 194.801712,600.614441 
              C195.286255,600.263733 195.770798,599.913086 196.255341,599.562439 
              C196.255341,469.869385 196.255341,340.176300 196.255341,209.543640 
              C206.954514,219.621964 216.906616,229.101486 226.981628,238.448532 
              C233.282181,244.293869 239.682098,250.039307 246.195923,255.645416 
              C252.076065,260.706116 255.330048,265.928741 255.293701,274.696320 
              C254.843246,383.351898 255.007339,492.009979 254.988297,600.667419 
              C254.986221,612.553711 250.104324,622.503357 242.299026,631.141785 
              C239.878220,633.820984 237.084229,636.162964 235.207993,637.948547 
              C261.154175,664.690857 286.562836,690.879089 311.971497,717.067383 
              C312.314484,716.828857 312.657501,716.590332 313.000488,716.351868 
              C313.000488,521.076721 313.000488,325.801636 313.000488,129.538422 
            z"/>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Drawing;


